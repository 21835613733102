@font-face {
  font-family: 'Mont-Black';
  src: url('../fonts/font.woff2') format('woff2'), url('../fonts/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*{
  box-sizing: border-box;
  /* overflow: hidden; */
}

svg {
  position: relative;
 }

.header {
  position: absolute;
  padding: 25px;
  padding-bottom: 19px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Mont-Black';
  background-color: #222222;
  letter-spacing: 1px;
  z-index: 100;
  border-bottom: #161616 solid 2px;
  background: repeating-linear-gradient(
  45deg,
  #222222,
  #222222 2px,
  #161616 2px,
  #161616 4px
);
}

.title {
  color: #999999
}

.subtitle {
  color: #555555;
}

.row {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}

.column {
  padding: 100px;
  padding-top: 187px;
  flex: 25%;
  align-content: center;
  text-align: center;
}

.one {
  background-color: #232F3E;
  color: #ffffff;
  z-index: 1;
  background-image: radial-gradient(#253448 20%, transparent 21%),
  radial-gradient(#253448 20%, transparent 21%);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.one a:link{
  color: #FFFFFF;
}

.one a:visited{
  color: #FFFFFF;
}

.two {
  background-color: #0096D6;
  color: #ffffff;
  z-index: 3;
  background-image: linear-gradient(#02a0e4, 2px, transparent 2px),
  linear-gradient(90deg, #02a0e4, 2px, transparent 2px),
  linear-gradient(rgba(255,255,255,.07) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255,255,255,.07) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 10px 10px, 10px 10px;
  background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.two a:link{
  color: #FFFFFF;
}

.two a:visited{
  color: #FFFFFF;
}

.three {
  background-color: #ffffff;
  color: #333333;
  z-index: 2;
  background:
    linear-gradient(-45deg, #ffffff 25%, transparent 25%, transparent 75%, #f4f4f4 75%, #f4f4f4) 0 0,
    linear-gradient(-45deg, #f4f4f4 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff) 1em 1em,
    linear-gradient(45deg, #f4f4f4 17%, transparent 17%, transparent 25%, #f4f4f4 25%, #f4f4f4 36%, transparent 36%, transparent 64%, #f4f4f4 64%, #f4f4f4 75%, transparent 75%, transparent 83%, #f4f4f4 83%) 1em 1em;
    background-color: #ffffff;
    background-size: 2em 2em;
}

.three a:link{
  color: #333333;
}

.three a:visited{
  color: #333333;
}

.columnTitle {
  display: block;
  position: relative;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  font-family: 'Mont-Black';
  letter-spacing: 1px;
}

.columnText {
  display: block;
  position: relative;
  margin-top: 25px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align:left;
  font-family: Arial, Helvetica, sans-serif;
}

.columnButton {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  font-family: 'Mont-Black';
  letter-spacing: 1px;
  padding: 2em;
  padding-bottom: 1.7em;
  border-radius: 10px;
}

.caseStudyColumn {
  padding: 100px;
  padding-top: 187px;
  flex: 33%;
  align-content: center;
  text-align: center;
}

.caseStudyLogo {
  width: 300px;
}

.caseStudyTitle {
  display: block;
  position: relative;
  margin-top: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  font-family: 'Mont-Black';
  letter-spacing: 1px;
}

.caseStudyText {
  display: block;
  position: relative;
  margin-top: 25px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align:left;
  font-family: Arial, Helvetica, sans-serif;
}

.caseStudyImage {
  position: relative;
}

.caseStudyVideo {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.null{
  background-color: #ffffff;
  color: #1c49c2;
}

.first{
  background-color: #ffffff;
  color: #232F3E;
}

.second{
  background-color: #ffffff;
  color: #0096D6;
}

.third{
  background-color: #333333;
  color: #ffffff;
}

@media only screen and ( max-width: 1367px) {

  .column {
    padding: 75px;
    padding-top: 187px;
  }

}

@media only screen and ( max-width: 1113px) {

  .column {
    padding: 50px;
    padding-top: 162px;
  }

}

@media only screen and ( max-width: 850px) {

  *{
    overflow:visible;
  }

  .header{
    text-align: center;
  }

  .row {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .column {
    padding: 50px;
    padding-top: 186px;
    filter: brightness(1.0);
  }

  .columnTitle {
    margin-top: 50px;
    padding-top: 50px;
    font-size: 18px;
    line-height: 24px;
  }

  .columnText {
    text-align:center;
    font-size: 16px;
    line-height: 21px;
    margin-top: 50px;
  }

  .columnButton{
    margin-top: 50px;
  }

  .caseStudyLogo {
    width: 100%;
  }

}